* {
  font-family: 'Poppins', sans-serif;
  font-family: 'Quicksand', sans-serif;
}



.z-index {
  z-index: 1;
}

.min-h-100vh {
  min-height: 100vh;
}

/* LOADER */

#loading-bg{
  display: block;
  height: 100%;
  position: absolute;
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  opacity: 0.5;
  background: #ffffff;
}
.loading-logo{
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 104px);
  top: calc(50% - 150px);
  z-index: 999999;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,  .loading .effect-2{
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgb(0, 123, 255);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1{
  animation: rotate 1s ease infinite;
}
.loading .effect-2{
  animation: rotateOpacity 1s ease infinite .1s;
}
.loading .effect-3{
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgb(0, 123, 255);
  -webkit-animation: rotateOpacity 1s ease infinite .2s;
  animation: rotateOpacity 1s ease infinite .2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects{
  transition: all .3s ease;
}

/* Navbar */

.navbar {
  background-color: #fff;
  /* color: #fff !important;
  padding: 12px 0; */
  height: 72px;
  position: sticky !important;
  display: block;
  padding: 15px 55px;
  box-shadow: 0px 4px 37px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 45px;
}

.navbar.is-pinned {
  background-color: #111111;
}

.navbar-btn {
  background: #2A2A2A;
  border: 1px solid #000000;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 25px;
}

.nav_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar_logo {
  color: #000;
  font-size: 16px;
  text-align: center;
  /*width: 125px;*/
  /*height: 40px;*/
}

.navbar_logo img {
  width: 136px;
  height: 100px;
  margin-bottom: 12px;
}

.navbar_logo_text {
  font-weight: bold;
}

.user_address {
  display: flex;
  align-items: center;
  background-color: #D9D9D9;
  justify-content: center;
  position: relative;
  color: #000000;
  max-width: 600px;
  width: 100%;
  height: 50px;
  padding: 15px 0;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.user_address_img {
  position: absolute;
  left: 10px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px !important;
}

.nav-link {
  padding: 20px 30px !important;
  font-size: 16px !important;
  font-weight: 600;
}

.nav-item a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
}

.nav-item-active a {
  color: #FF724C;
  text-decoration: none;
  margin-right: 30px;
  font-size: 16px;
}

.nav_icon {
  cursor: pointer;
}

.language_icon {
  color: #BCBCBC;
  cursor: pointer;
  margin-left: 45px;
}

.language_icon path {
  stroke: #BCBCBC;
}

.nav_icon path {
  color: #BCBCBC;
  stroke: #BCBCBC !important;
  margin-left: 15px;
}

.nav_btn {
  border: 1px solid #A3A3A3;
  background-color: transparent;
  color: #fff;
  width: 172px;
  height: 45px;
  margin-right: 15px;
}

.nav_btn:hover {
  color: #fff;
}

.nav_btn:focus {
  box-shadow: none;
}

.box-shadow-none {
  box-shadow: none !important;
}

.nav-min-h-100vh {
  min-height: calc(100vh - 136px);
}

/* Navbar end */

/* Sidebar */

.sidebar {
  position: relative;
  display: flex;
  flex: 0 0 330px;
  flex-direction: column;
  background-color: #2B2B2B;
  order: -1;
  width: 330px;
  min-height: 100vh;
  height: 100%;
  box-shadow: none;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;
}

.sidebar_fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
}

.sidebar_logo {
  height: 140px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

.sidebar-email {
  background: #4B4B4B;
  border-radius: 100px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width: fit-content;
  margin: auto;
  margin-top: 0;
  margin-bottom: 25px;
}

.sidebar_link {
  font-size: 18px;
  font-weight: 600;
  color: #BFBFBF;
  cursor: pointer;
}

.sidebar_wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
  margin-left: 256px;
  transition: margin 0.3s;
}

.sidebar-nav-item {
  width: inherit;
  color: #BFBFBF !important;
  font-weight: 600;
  font-size: 15px;
  height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar-nav-item .sidebar-nav-border {
  padding-left: 24px;
  height: 40px;
}

.sidebar-nav-item:hover {
  transition: 0.3s;
  background-color: #000000;
  color: #FFFFFF !important;
}

.sidebar-nav-item-logo svg {
  margin-right: 15px;
  width: 25px;
  height: 25px;
}

.sidebar-nav-active {
  transition: 0.3s;
  background-color: #000000;
  color: #FFFFFF !important;
  padding: 20px 0 20px 0;
}

.sidebar-nav-active .sidebar-nav-border {
  border-left: 4px solid #4570ba;
  padding-left: 24px;
  height: 40px;
}

.sidebar-nav-children {
  background-color: #000000;
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 0;
  height: inherit;
  width: inherit;
}

.sidebar-child {
  color: #BFBFBF !important;
  padding: 12px 0 12px 28px;
}

.sidebar-child-active {
  color: #fff !important;
  padding: 12px 0 12px 28px;
  height: inherit;
}

.mobile_sidebar {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding: 30px 0 0 28px;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;
}

.mobile_sidebar_close_btn {
  display: none;
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 1300;
  cursor: pointer;
}

.sidebar_open_btn {
  display: none;
  padding: 30px 10px 0;
  width: max-content;
  background-color: #F3F3F3;
}

.sidebar-logout {
  position: absolute;
  bottom: 0;
  background: #4B4B4B;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
}

/* Sidebar end */

/* Login */

.login_page {
  width: 100%;
  height: 100vh;
}

.login_bg {
  width: 100%;
  height: 100vh;
  background-image: url('../images/login/login_bg.jpeg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_title {
  margin-bottom: 100px;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.login_label {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}

.login_input {
  width: 100%;
  background: #F3F3F3;
  border: 1.5px solid #E8E8E8;
  border-radius: 7px;
  outline: 0;
  height: 45px;
  padding: 11px 16px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 30px;
}

.login_checkbox {
  accent-color: #4570ba;
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.login_checkbox_label {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #A0A0A0;
}

.error_text {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FF0000;
  opacity: 0;
}

.error_text_show {
  opacity: 1;
}

.login_btn {
  background: linear-gradient(91.78deg, #E2007F 4.92%, #1E4CED 95.68%);
  border-radius: 7px;
  padding: 0 60px;
  height: 55px;
  box-shadow: none;
  outline: 0;
  border: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 0px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px;
}

/* Login end */

/* Page */

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #1F1F1F;
  margin-bottom: 20px;
}

.custom-select {
  position: relative;
  padding: 0 16px;
  background: #FFFFFF;
  border: 1.5px solid #DEDEDE;
  border-radius: 7px;
  min-width: 180px;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
}

.custom-select-active {
  background: #F0F0F0;
  border: 1.5px solid #DEDEDE;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 3;
}

.custom-select-active .custom-select-icon {
  background: #A0A0A0;
}

.custom-select:hover {
  transition: 0.2s;
  background: #F0F0F0;
  border: 1.5px solid #DEDEDE;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.custom-select:hover .custom-select-icon {
  transition: 0.2s;
  background: #A0A0A0;
}

.custom-select-icon {
  position: absolute;
  right: 15px;
  background: #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #FFFFFF;
}

.custom-select-options {
  display: none;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  top: 120%;
  border-radius: 7px;
}

.custom-select-options-show {
  display: block;
}

.custom-select-option {
  padding: 11px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F;
}

.custom-select-option-active {
  padding: 11px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #385B96;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.input-search {
  position: relative;
  max-width: 473px;
  width: 100%;
  margin-right: 20px;
}

.input-search input {
  padding-left: 40px;
  height: 45px;
  background: #F0F0F0;
  border: 1.5px solid #DEDEDE;
  border-radius: 7px;
  outline: 0;
  max-width: 473px;
  width: 100%;
}

.input-search input::placeholder {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #A0A0A0;
}

.input-search-icon {
  position: absolute;
  left: 16px;
  top: 18%;
  color: #A0A0A0;
  cursor: pointer;
}

.main_btn {
  background: #385B96;
  border: 1px solid #385B96;
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 0 30px;
  height: 45px;
  position: relative;
}

.main_btn:hover {
  transition: 0.3s;
  background: #304e82;
  box-shadow: 4px 4px 40px 1px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}

/* Table */

.table tbody tr td {
  background: #fff;
  padding: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1F1F1F;
  border-bottom: 1px solid #EAEAEA;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



/* Modal */

.modal-header {
  gap: 10px;
}

.main_modal_body {
  width: 420px;
  height: 370px;
  border-radius: 7px;
}

.main_modal_body .modal-footer {
  border-top: 0;
  display: block;
  padding: 0;
}

.main_modal_text {
  color: #1F1F1F;
  font-weight: 700;
  font-size: 20px;
}

.main_modal_btn_001 {
  background: #EAEAEA;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 100%;
  border-radius: 0px 0px 0px 7px;
  font-weight: 700;
  color: #2A2A2A;
}

.main_modal_btn_002 {
  background: #385B96;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 100%;
  border-radius: 0px 0px 7px 0px;
  font-weight: 700;
  color: #fff;
}

.modal-footer>* {
  margin: 0;
}

/* Modal end */
.image_upload {
  display: flex;

  gap: 10px;
}

.image_upload_input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.w-md-50 {
  width: 50%;
}

@media (max-width: 568px) {
  .dropdown-li {
    height: 100%;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  /* .flex-sm-wrap {
    flex-wrap: wrap;
  } */
  .nav-container {
    padding: 0;
  }

  .navbar-toggler {
    margin-right: 20px;
  }

  .nav_content {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 992px) {
  .nav_content {
    display: block;
    background-color: transparent;
    padding: 20px;
    padding-right: 0;
  }

  .nav-item {
    display: block;
    margin: 10px 0;
  }

  .carousel_dots li {
    width: 20px;
  }

  .nav-container {
    background-color: transparent;
  }

  .navbar-collapse {
    background-color: #fff;
    padding: 10px 20px;
  }

  .navbar {
    padding: 0 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .w-md-50 {
    width: 100%;
  }

  .sidebar {
    position: absolute;
    z-index: 1200;
    width: 100vw;
    height: auto;
  }

  .mobile_header {
    display: block;
  }

  .mobile_sidebar {
    display: block;
  }

  .sidebar_open_btn {
    display: block;
  }

  .mobile_sidebar_close_btn {
    display: block;
  }
}

.select_area {
  display: flex;
  gap: 20px;
}

.select_area .detail_block, .form-select {
  margin-bottom: 0;
  width: 150px;
  height: 45px;
}

.set_item_add {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.set_items {
  width: 180px;
  height: 180px;
  border: 1px solid;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
}

.set_item_name {
  color: #385B96;
  height: 25px;
}

.order{
  display: flex;
  align-items: center;
  gap: 10px;

}
.order .detail_block {
  margin-bottom: 0;
}

.modal-scroll-table{
  max-height: 500px;
  overflow: auto;
}