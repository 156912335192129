/* main-content */
.main-content {
    box-sizing: border-box;
    *, *::before, *::after {
        box-sizing: border-box;
    }
    .container {
        display: table;
        width: 1560px;
        margin: 0 auto;
        padding: 40px 0;
        section {
            width: 50%;
            display: table-cell;
            vertical-align: top;
            article {
                .article-header {
                    position: relative;
                    text-align: center;
                    color: #fff;
                    padding: 20px 0;
                    background-color: #002060;
                    h3 {
                        font-size: 20px;
                    }
                    .note {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        font-size: 14px;
                        transform: translateY(-50%);
                    }
                }
                .article-content {
                    padding-top: 10px;
                    .graph-area {
                        margin-bottom: 10px;
                        & ~ .graph-area {
                            margin-top: 10px;
                        }
                    }
                    .graph-list {
                        overflow: hidden;
                        margin-top: 10px;
                        > ul {
                            width: calc(100% + 10px);
                            margin: 0 -5px;
                            > li {
                                float: left;
                                width: 25%;
                                padding: 0 5px;
                                dl {
                                    > dt {
                                        display: block;
                                        width: calc(100% - 20px);
                                        text-align: center;
                                        font-size: 16px;
                                        line-height: 30px;
                                        margin: 0 auto 10px;
                                        border: 1px solid #d8dee5;
                                    }
                                    > dd {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
                & ~ article {
                    margin-top: 40px;
                }
            }
            &:nth-of-type(2n + 1) {
                padding-right: 15px;
            }
            &:nth-of-type(2n + 2) {
                padding-left: 15px;
            }
        }
    }
}


.table-wrap {
    .table {
        width: 100%;
        table-layout: fixed;
        thead {
            tr {
                th {
                    font-size: 13px;
                    color: #222;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 0;
                    border-top: 1px solid #d8dee5;
                    border-bottom: 1px solid #d8dee5;
                    border-right: 1px solid #d8dee5;
                    background-color: #f2f2f2;
                    &:first-child {
                        border-left: 1px solid #d8dee5;
                    }
                    .color-box {
                        display: inline-block;
                        vertical-align: middle;
                        width: 15px;
                        height: 15px;
                        margin-left: 5px;
                        margin-top: -5px;
                    }
                    &.emphasis {
                        background-color: #dbeef4;
                    }
                }
            }
        }
        tbody {
            tr {
                th {
                    font-size: 13px;
                    color: #222;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 0;
                    border-bottom: 1px solid #d8dee5;
                    border-right: 1px solid #d8dee5;
                    background-color: #f2f2f2;
                    &:first-child {
                        border-left: 1px solid #d8dee5;
                    }
                }
                td {
                    font-size: 13px;
                    color: #222;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 0;
                    border-bottom: 1px solid #d8dee5;
                    border-right: 1px solid #d8dee5;
                    &:first-child {
                        border-left: 1px solid #d8dee5;
                    }
                    &.emphasis {
                        background-color: #dbeef4;
                    }
                    &.total {
                        background-color: #fdeada;
                    }
                }
                & ~ tr {
                    td {
                        border-top: 1px solid #d8dee5;
                    }
                }
                &.total {
                    td {
                        background-color: #fdeada;
                    }
                }
            }
        }
    }
}

.double {
    overflow: hidden;
    .table-wrap {
        float: left;
        width: 50%;
        &:nth-of-type(1) {
            padding-right: 5px;
        }
        &:nth-of-type(2) {
            padding-left: 5px;
        }
    }
    &.type-2 {
        .table-wrap {
            &:nth-of-type(1) {
                width: 210px;
                padding-right: 10px;
            }
            &:nth-of-type(2) {
                padding: 0;
                width: calc(100% - 210px);
            }
        }
    }
}