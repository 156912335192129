@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

/* table-wrap */
.table-wrap table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border: 0;
}

.table-wrap table.bottom-line {
	border-bottom: 1px solid #d8dee5;
}

.table-wrap table thead tr th {
	font-size: 16px;
	color: #fff;
	height: 72px;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	border-right: 1px solid #d8dee5;
	border-bottom: 1px solid #d8dee5;
	background-color: #8895aa;
}

.table-wrap table tbody tr th {
	font-size: 16px;
	color: #fff;
	height: 72px;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	border-bottom: 1px solid #d8dee5;
	background-color: #8895aa;
}

.table-wrap table tbody tr th.necessary:after {
	color: #ff6b01;
	content: ' *';
}

.table-wrap table tbody tr th.border-0 {
	border-bottom: 0;
}

.table-wrap table tbody tr th.total {
	background-color: #598dee;
}

.table-wrap table tbody tr td {
	height: 72px;
	padding: 14px 24px;
	vertical-align: middle;
	border-bottom: 1px solid #d8dee5;
	background-color: #fff;
}

.table-wrap table tbody tr td .form-group dl dt {
	display: inline-block;
	padding-right: 10px;
	line-height: 43px;
}

.table-wrap table tbody tr td .form-group dl dd {
	display: inline-block;
}

.table-wrap table tbody tr td .form-group dl dd .form-item .inp {
	width: 130px;
}

.table-wrap table tbody tr td .form-area.col2 {
	overflow: hidden;
}

.table-wrap table tbody tr td .form-area.col2 .form-item {
	float: left;
	width: calc(50% - 10px);
	margin-right: 20px;
}

.table-wrap table tbody tr td .form-area.col2 .form-item:last-child {
	margin-right: 0;
}

.table-wrap table tbody tr td .form-area.col2 .form-item .btn-area>.btn {
	float: left;
	line-height: 43px;
	text-align: center;
	color: #fff;
	width: calc(50% - 5px);
	border-radius: 5px;
	margin-right: 10px;
	background-color: #223995;
}

.table-wrap table tbody tr td .form-area.col2 .form-item .btn-area>.btn:last-child {
	margin-right: 0;
}

.table-wrap table tbody tr td .form-area.col2 .form-item.type-range.col1 .inp {
	width: calc(100% - 70px);
}

.table-wrap table tbody tr td .form-area.col2 .form-item.type-range.col1 .form-division {
	width: 70px;
	padding-left: 10px;
}

.table-wrap table tbody tr td .link {
	color: var(--primary-color);
}

.table-wrap table tbody tr td .file {
	color: var(--primary-color);
}

.table-wrap table tbody tr td.left {
	text-align: left;
}

.table-wrap table tbody tr td.border-0 {
	border-bottom: 0;
}

.table-wrap table tbody tr:last-child th, .table-wrap table tbody tr:last-child td {
	border-bottom: 0;
}

.table-wrap table.type-2 {
	border-top: 1px solid #d8dee5;
}

.table-wrap table.type-2 tbody tr td {
	text-align: center;
	border-right: 1px solid #d8dee5;
}

.table-wrap table.type-2 tbody tr td:last-child {
	border-right: 0;
}

/* btn-controls */
.btn-controls {
	font-size: 0;
}

.btn-controls>.btn {
	display: inline-block;
	font-size: 16px;
	vertical-align: middle;
	line-height: 43px;
	color: #333;
	text-align: center;
	width: 60px;
	border-radius: 5px;
	margin-right: 10px;
	background-color: #d9d9d9;
}

.btn-controls>.btn:last-child {
	margin-right: 0;
}

/* radio-box */
.radio-box .rdo-txt {
	font-size: 16px;
	color: #000;
	cursor: pointer;
	font-weight: 500;
	margin-left: 30px;
	padding-left: 30px;
	background-image: url(../img/icon/ico_radio_type1.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 0 50%;
}

.radio-box .rdo-txt:first-of-type {
	margin-left: 0;
}

.radio-box input:checked+.rdo-txt {
	background-image: url(../img/icon/ico_radio_type1_on.png);
}

/* check-box */
.check-box .lb-chk {
	display: inline-block;
	margin-left: 30px;
}

.check-box .lb-chk:first-of-type {
	margin-left: 0;
}

.form-item.overlap-check {
	position: relative;
	padding-right: 100px;
}

.form-item.overlap-check .btn-check {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	text-align: center;
	cursor: pointer;
	width: 90px;
	font-weight: 400;
	border-radius: 5px;
	line-height: 43px;
	background-color: #d9d9d9;
}

.form-item.overlap-check.big {
	padding-right: 130px;
}

.form-item.overlap-check.big .btn-check {
	width: 120px;
}

.form-item.file-area .file-upload {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	vertical-align: top;
	width: 100px;
	font-weight: 400;
	border-radius: 5px;
	line-height: 43px;
	margin-right: 7px;
	background-color: #d9d9d9;
}

.form-item.file-area input[type="file"] {
	display: none;
}

.form-item.file-area p.inp {
	font-size: 14px;
	color: #999;
	width: calc(100% - 111px);
	font-weight: 300;
}

.form-item.file-area.file-active .file {
	display: inline-block;
	width: 180px;
	vertical-align: middle;
}

.form-item.file-area.file-active p.inp {
	width: calc(100% - 294px);
}

.form-item.addr {
	position: relative;
	padding-left: 130px;
}

.form-item.addr .btn-addr {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	text-align: center;
	cursor: pointer;
	width: 120px;
	font-weight: 400;
	border-radius: 5px;
	line-height: 43px;
	background-color: #d9d9d9;
}

.form-item.addr input {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 525px);
}

.form-item.addr input:first-of-type {
	width: 500px;
	margin-right: 10px;
}

article.space {
	margin-top: 65px;
}

.sub-title {
	font-size: 27px;
	font-weight: 700;
}

.sub-title.blue {
	color: #223995;
}

.article-header {
	padding: 65px 0 30px;
}

.article-header.type-2 {
	padding-bottom: 30px;
}

.join-success {
	text-align: center;
}

.join-success .article-content .tbl-area {
	width: 600px;
	margin: 0 auto;
}

.btn-area.left {
	text-align: left;
}

.btn-area.right {
	text-align: right;
}

.btn-area .pw-write, .btn-area .btn-login {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	width: 120px;
	font-weight: 400;
	border-radius: 5px;
	line-height: 43px;
	background-color: #d9d9d9;
}

.btn-area .pw-write.btn-login, .btn-area .btn-login.btn-login {
	width: 100%;
	max-width: 120px;
}

textarea.inp {
	height: 100px;
	padding: 14px;
}

/* small-pop */
.small-pop {
	padding: 0 15px;
	padding-bottom: 60px;
}

.small-pop .popup-header {
	width: 100%;
}

.small-pop .article-header {
	position: relative;
	/*text-align: center;*/
	padding: 40px 0 30px;
}

.small-pop .article-header.left {
	text-align: left;
}

.small-pop .article-header h5 {
	font-size: 20px;
}

.small-pop .article-header p.center {
	text-align: center;
}

.small-pop .article-header p.blue {
	color: #042dbe;
}

.small-pop .article-header .btn-close {
	background: none;
	position: absolute;
	top: 0;
	right: 0;
}

.small-pop .article-header .dott-list {
	text-align: left;
}

.small-pop .article-header .dott-list>li {
	position: relative;
	padding-left: 15px;
}

.small-pop .article-header .dott-list>li:before {
	position: absolute;
	top: 9px;
	left: 0;
	width: 4px;
	height: 4px;
	display: block;
	border-radius: 50%;
	content: '';
	background-color: #222;
}

.small-pop .article-header .dott-list>li.note {
	color: #e94316;
}

.small-pop .article-header .dott-list>li.note:before {
	background-color: #e94316;
}

.small-pop .article-content>figure img {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.small-pop .article-content .attention {
	color: #999;
	text-align: center;
	margin-top: 60px;
}

.small-pop .article-content .btn-area {
	margin-top: 60px;
}

.tbl-base td .subject {
	display: block;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 5px;
	text-overflow: ellipsis;
}

.tbl-base td .file-list>a {
	margin-right: 10px;
}

.tbl-base td .file-list>a:last-child {
	margin-right: 0;
}

.popup-header.type-2 {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.cont-area__grid.type-2 {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.pb-60 {
	padding-bottom: 60px !important;
}

.product-num {
	/*display: table;
  width: 100%;
  table-layout: fixed;*/
}

.product-num>div {
	display: block;
	/*display: table-cell;
    vertical-align: top;*/
}

.product-num>div.item {
	/*padding-right: 5px;*/
}

.product-num>div.item img {
	display: block;
	width: 100%;
}

.product-num>div.summary {
	/*padding-left: 5px;*/
	margin-top: 20px;
}

/* tbl-upper */
.tbl-upper .tbl-upper__util .top-board {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	color: #223995;
	padding: 0 20px;
	line-height: 43px;
	border-radius: 5px;
	border: 1px solid #9fa8c0;
	background-color: #F8F8F8;
}

/* file-upload */
.file-upload {
	overflow: hidden;
}

.file-upload .btn-file {
	float: left;
	cursor: pointer;
	width: 140px;
	color: #fff;
	line-height: 54px;
	text-align: center;
	border-radius: 5px;
	background-color: #223995;
}

.file-upload input[type="file"] {
	float: left;
	height: 54px;
	max-width: 300px;
	width: calc(100% - 150px);
	margin-left: 10px;
	border-radius: 5px;
	border: 1px solid #9fa8c0;
}

.file-upload .file-list {
	float: left;
	width: calc(100% - 150px);
	margin-left: 10px;
}

.file-upload .file-list p {
	display: block;
	font-size: 16px;
	color: #223995;
	line-height: 54px;
}

/* format-area */
.format-area .btn-format {
	width: 140px;
	color: #fff;
	line-height: 54px;
	text-align: center;
	border-radius: 5px;
	background-color: #7f7f7f;
}

.dott-list>li {
	position: relative;
	padding-left: 10px;
	font-size: 16px;
	line-height: 26px;
}

.dott-list>li span {
	color: #e94316;
}

.dott-list>li:before {
	position: absolute;
	top: 11px;
	left: 0;
	width: 2px;
	height: 2px;
	border-radius: 50%;
	display: block;
	content: '';
	background-color: #000;
}

/* board-frame */
.board-frame {
	display: block;
	width: 100%;
	line-height: 52px;
	text-align: center;
	color: #fff;
	overflow: hidden;
	background-color: #558ed5;
}

.board-frame.type-2 {
	line-height: normal;
	padding: 30px 0;
}

.board-frame span {
	color: #ffff00;
}

.board-frame .under-txt:before {
	display: inline-block;
	content: '|';
	color: #fff;
	margin: 0 10px;
}

.board-frame>div {
	float: left;
	width: 50%;
	line-height: 52px;
	text-align: center;
	color: #fff;
}

.board-frame>div:nth-of-type(1) {
	background-color: #558ed5;
}

.board-frame>div:nth-of-type(2) {
	background-color: #ff7c80;
}

/* summary-top */
.summary-top.col4 {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.summary-top.col4>div {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

.summary-top.col4>div.summary-list {
	text-align: left;
	width: 67%;
	padding: 30px;
	background-color: #4e83e6;
}

.summary-top.col4>div.summary-list ol>li {
	color: #fff;
	line-height: 26px;
}

.summary-top.col4>div.bank-info {
	text-align: center;
}

.summary-top.col4>div.bank-info>dl {
	display: table;
	width: 100%;
	height: 100%;
}

.summary-top.col4>div.bank-info>dl>dt, .summary-top.col4>div.bank-info>dl dd {
	height: 164px;
	display: table-cell;
	vertical-align: middle;
}

.summary-top.col4>div.bank-info>dl>dt {
	width: 100px;
	color: #fff;
	background-color: #e94316;
}

.summary-top.col4>div.bank-info>dl>dd {
	overflow: hidden;
	color: #fff;
	background-color: #333;
}

.summary-top.col4>div.bank-info>dl>dd>ul {
	font-size: 0;
}

.summary-top.col4>div.bank-info>dl>dd>ul li {
	position: relative;
	display: inline-block;
	width: 50%;
	font-size: 16px;
}

.summary-top.col4>div.bank-info>dl>dd>ul li:after {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 300px;
	transform: translateY(-50%);
	display: block;
	background-color: rgba(255, 255, 255, 0.6);
	content: '';
}

.summary-top.col4>div.bank-info>dl>dd>ul li:last-child:after {
	display: none;
}

/* files */
.files>li>.file {
	position: relative;
	display: inline-block;
}

.files>li>.file>figure {
	display: inline-block;
}

.files>li>.file>figure img {
	width: 20px;
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.files>li>.file>figure figcaption {
	color: #333;
	font-size: 14px;
	font-weight: 300;
	margin-top: 5px;
}

.files>li>.file .btn-del {
	position: absolute;
	top: -10px;
	right: 10px;
	display: block;
}

.files>li~li {
	margin-top: 10px;
}

.article-content.type-2 {
	overflow: hidden;
}

.article-content.type-2 .box {
	float: left;
	width: 970px;
}

.article-content.type-2 .box.left {
	width: calc(100% - 1000px);
	margin-right: 30px;
}

.article-content.type-2 .box.left .tbl-area__tit {
	display: block;
	text-align: center;
	line-height: 43px;
	color: #000;
}

.article-content.type-2 .box.left .table-wrap table tbody tr td {
	text-align: left;
}

.introduce {
	width: 1560px;
	margin: 0 auto;
}

.introduce article {
	text-align: center;
}

.introduce article .article-title small {
	display: block;
	font-size: 20px;
	color: #222;
	font-weight: 400;
}

.introduce article .article-title h3 {
	font-size: 32px;
	color: #222;
	font-weight: bold;
}

.introduce article .article-title.white h3 {
	color: #fff;
}

.introduce article .article-title.white small {
	color: #fff;
}

.introduce article.article-1 {
	padding: 90px 0 100px;
}

.introduce article.article-1 .article-title {
	padding-bottom: 50px;
}

.introduce article.article-1 .article-content figure img {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.introduce article.article-1 .article-content figure figcaption {
	font-size: 20px;
	line-height: 36px;
	color: #222;
	font-weight: 400;
	padding-top: 50px;
}

.introduce article.article-2 {
	padding: 85px 0;
	background-image: url(../img/bg_product.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.introduce article.article-2 .article-title {
	padding-bottom: 50px;
}

.introduce article.article-2 .article-content>ul {
	text-align: center;
	font-size: 0;
}

.introduce article.article-2 .article-content>ul>li {
	display: inline-block;
	width: 160px;
	margin-right: 23px;
	vertical-align: top;
}

.introduce article.article-2 .article-content>ul>li>a {
	display: block;
	width: 100%;
	background-color: #fff;
	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.57);
}

.introduce article.article-2 .article-content>ul>li>a>figure>img {
	width: 100%;
	display: block;
}

.introduce article.article-2 .article-content>ul>li>a>figure figcaption {
	padding-bottom: 25px;
}

.introduce article.article-2 .article-content>ul>li>a>figure figcaption b {
	display: block;
	font-size: 20px;
	color: #000;
	font-weight: 500;
}

.introduce article.article-2 .article-content>ul>li>a>figure figcaption p {
	margin: 5px 0 15px;
	font-size: 13px;
	color: #989898;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
}

.introduce article.article-2 .article-content>ul>li>a>figure figcaption img {
	display: block;
	margin: 0 auto;
}

.introduce article.article-2 .article-content>ul>li:last-child {
	margin-right: 0;
}

.introduce article.article-3 {
	padding: 110px 0 90px;
}

.introduce article.article-3 .article-title {
	padding-bottom: 70px;
}

.introduce article.article-3 .article-content>ol {
	font-size: 0;
}

.introduce article.article-3 .article-content>ol>li {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding: 0 45px;
}

.introduce article.article-3 .article-content>ol>li:after {
	position: absolute;
	top: 50%;
	right: -24px;
	display: block;
	transform: translateY(-50%);
	content: url(../img/ico_step_arrow.png);
}

.introduce article.article-3 .article-content>ol>li:last-child:after {
	display: none;
}

.introduce article.article-3 .article-content>ol>li>figure img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
}

.introduce article.article-3 .article-content>ol>li>figure figcaption {
	padding-top: 30px;
}

.introduce article.article-3 .article-content>ol>li>figure figcaption>dl>dt {
	font-size: 20px;
	font-weight: 500;
	color: #000;
}

.introduce article.article-3 .article-content>ol>li>figure figcaption>dl>dd {
	display: block;
	font-size: 16px;
	line-height: 20px;
	color: #000;
	font-weight: 300;
	line-height: 20px;
	margin-top: 10px;
}

.introduce article.article-3 .article-content>ol>li>figure figcaption>dl>dd span {
	display: block;
	color: #8d8d8d;
}

.inquiry {
	text-align: center;
	padding: 90px 0;
	background-color: #f0f4fc;
}

.inquiry .container {
	width: 1560px;
	margin: 0 auto;
}

.inquiry .container .article-title {
	padding-bottom: 40px;
}

.inquiry .container .article-title h3 {
	font-size: 32px;
	color: #222;
	font-weight: bold;
}

.inquiry .container .article-content .company-info {
	margin-top: 45px;
}

.inquiry .container .article-content .company-info p {
	font-size: 20px;
	line-height: 40px;
	font-weight: 300;
	color: #222;
	vertical-align: top;
}

.inquiry .container .article-content .company-info p b {
	display: inline-block;
	vertical-align: top;
	font-weight: 500;
	line-height: 40px;
	padding-right: 5px;
}

.inquiry .container .article-content .company-info p b.blue {
	color: #15459d;
	padding-right: 15px;
}

.default-board {
	border: 1px solid #d8dee5;
}

.default-board .board-header {
	background-color: #f8f8f8;
	padding: 10px 20px;
}

.default-board .board-header h5 {
	font-size: 20px;
}

.default-board .board-content {
	padding: 20px;
	min-height: 500px;
	border-top: 1px solid #d8dee5;
}

.default-board .file-list {
	padding: 10px 20px;
	border-top: 1px solid #d8dee5;
}

.default-board .file-list ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
}

.default-board .file-list ul li:last-child {
	margin-right: 0;
}

.default-board .file-list ul li>a {
	display: block;
}

.default-board .file-list ul li>a img {
	width: 15px;
}

