@charset 'UTF-8';

@font-face{ 
	font-family:'NotoSans';
	font-weight:100;
	src:url('../font/NotoSans-Thin.eot');
	src:url('../font/NotoSans-Thin.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-Thin.woff2') format('woff2'), 
			url('../font/NotoSans-Thin.woff') format('woff'), 
			url('../font/NotoSans-Thin.otf') format('truetype');
}
@font-face{ 
	font-family:'NotoSans';
	font-weight:300;
	src:url('../font/NotoSans-DemiLight.eot');
	src:url('../font/NotoSans-DemiLight.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-DemiLight.woff2') format('woff2'), 
			url('../font/NotoSans-DemiLight.woff') format('woff'), 
			url('../font/NotoSans-DemiLight.otf') format('truetype');
}
@font-face{ 
	font-family:'NotoSans';
	font-weight:400;
	src:url('../font/NotoSans-Regular.eot');
	src:url('../font/NotoSans-Regular.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-Regular.woff2') format('woff2'), 
			url('../font/NotoSans-Regular.woff') format('woff'), 
			url('../font/NotoSans-Regular.ttf') format('truetype');
}
@font-face{ 
	font-family:'NotoSans';
	font-weight:500;
	src:url('../font/NotoSans-Medium.eot');
	src:url('../font/NotoSans-Medium.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-Medium.woff2') format('woff2'), 
			url('../font/NotoSans-Medium.woff') format('woff'), 
			url('../font/NotoSans-Medium.ttf') format('truetype');
}
@font-face{ 
	font-family:'NotoSans';
	font-weight:700;
	src:url('../font/NotoSans-Bold.eot');
	src:url('../font/NotoSans-Bold.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-Bold.woff2') format('woff2'), 
			url('../font/NotoSans-Bold.woff') format('woff'), 
			url('../font/NotoSans-Bold.ttf') format('truetype');
	}
@font-face{ 
	font-family:'NotoSans';
	font-weight:800;
	src:url('../font/NotoSans-Black.eot');
	src:url('../font/NotoSans-Black.eot?#iefix') format('embedded-opentype'), 
			url('../font/NotoSans-Black.woff2') format('woff2'), 
			url('../font/NotoSans-Black.woff') format('woff'), 
			url('../font/NotoSans-Black.ttf') format('truetype');
}
@font-face{ 
	font-family:'GmarketSans';
	font-weight:300;
	src:url('../font/GmarketSansLight.woff2') format('woff2'), 
			url('../font/GmarketSansLight.woff') format('woff');
}
@font-face{ 
	font-family:'GmarketSans';
	font-weight:400;
	src:url('../font/GmarketSansMedium.woff2') format('woff2'), 
			url('../font/GmarketSansMedium.woff') format('woff');
}
@font-face{ 
	font-family:'GmarketSans';
	font-weight:700;
	src:url('../font/GmarketSansBold.woff2') format('woff2'), 
			url('../font/GmarketSansBold.woff') format('woff');
}
@font-face{ 
	font-family:'scdream';
	font-weight:400;
	src:url('../font/scdream5-webfont.woff2') format('woff2'), 
			url('../font/scdream5-webfont.woff') format('woff');
}
@font-face{ 
	font-family:'scdream';
	font-weight:700;
	src:url('../font/scdream7-webfont.woff2') format('woff2'), 
			url('../font/scdream7-webfont.woff') format('woff');
}
@font-face{ 
	font-family:'scdream';
	font-weight:800;
	src:url('../font/scdream9-webfont.woff2') format('woff2'), 
			url('../font/scdream9-webfont.woff') format('woff');
}