@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

/* table-wrap */
.table-wrap {
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        border: 0;
        &.bottom-line {
            border-bottom: 1px solid #d8dee5;
        }
        thead {
            tr {
                th {
                    font-size: 16px;
                    color: #fff;
                    height: 72px;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    border-right: 1px solid #d8dee5;
                    border-bottom: 1px solid #d8dee5;
                    background-color: #8895aa;
                }
            }
        }
        tbody {
            tr {
                th {
                    font-size: 16px;
                    color: #fff;
                    height: 72px;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    border-bottom: 1px solid #d8dee5;
                    background-color: #8895aa;
                    &.necessary {
                        &:after {
                            color: #ff6b01;
                            content: ' *';
                        }
                    }
                    &.border-0 {
                        border-bottom: 0;
                    }
                    &.total {
                        background-color: #598dee;
                    }
                }
                td {
                    height: 72px;
                    padding: 14px 24px;
                    vertical-align: middle;
                    border-bottom: 1px solid #d8dee5;
                    background-color: #fff;
                    .form-group {
                        dl {
                            dt {
                                display: inline-block;
                                padding-right: 10px;
                                line-height: 43px;
                            }
                            dd {
                                display: inline-block;
                                .form-item {
                                    .inp {
                                        width: 130px;
                                    }
                                }
                            }
                        }
                    }
                    .form-area {
                        &.col2 {
                            overflow: hidden;
                            .form-item {
                                float: left;
                                width: calc(50% - 10px);
                                margin-right: 20px;
                                &:last-child {
                                    margin-right: 0;
                                }
                                .btn-area {
                                    > .btn {
                                        float: left;
                                        line-height: 43px;
                                        text-align: center;
                                        color: #fff;
                                        width: calc(50% - 5px);
                                        border-radius: 5px;
                                        margin-right: 10px;
                                        background-color: #223995;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                                &.type-range {
                                    &.col1 {
                                        .inp {
                                            width: calc(100% - 70px);
                                        }
                                        .form-division {
                                            width: 70px;
                                            padding-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .link {
                        color: var(--primary-color);
                    }
                    .file {
                        color: var(--primary-color);
                    }
                    &.left {
                        text-align: left;
                    }
                    &.border-0 {
                        border-bottom: 0;
                    }
                }
                &:last-child {
                    th, td {
                        border-bottom: 0;
                    }
                }
            }
        }
        &.type-2 {
            border-top: 1px solid #d8dee5;
            tbody {
                tr {
                    td {
                        text-align: center;
                        border-right: 1px solid #d8dee5;
                        &:last-child {
                            border-right: 0;
                        }
                    }
                }
            }
        }
    }
}

/* btn-controls */
.btn-controls {
    font-size: 0;
    > .btn {
        display: inline-block;
        font-size: 16px;
        vertical-align: middle;
        line-height: 43px;
        color: #333;
        text-align: center;
        width: 60px;
        border-radius: 5px;
        margin-right: 10px;
        background-color: #d9d9d9;
        &:last-child {
            margin-right: 0;
        }
    }
}

/* radio-box */
.radio-box {
    .rdo-txt {
        font-size: 16px;
        color: #000;
        cursor: pointer;
        font-weight: 500;
        margin-left: 30px;
        padding-left: 30px;
        background-image: url(/resource/img/icon/ico_radio_type1.png);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: 0 50%;
        &:first-of-type {
            margin-left: 0;
        }
    }
    input:checked + .rdo-txt {
        background-image: url(/resource/img/icon/ico_radio_type1_on.png);
    }
}

/* check-box */
.check-box {
    .lb-chk {
        display: inline-block;
        margin-left: 30px;
        &:first-of-type {
            margin-left: 0;
        }
    }
}

.form-item {
    &.overlap-check {
        position: relative;
        padding-right: 100px;
        .btn-check {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            text-align: center;
            cursor: pointer;
            width: 90px;
            font-weight: 400;
            border-radius: 5px;
            line-height: 43px;
            background-color: #d9d9d9;
        }
        &.big {
            padding-right: 130px;
            .btn-check {
                width: 120px;
            }
        }
    }
    &.file-area {
        .file-upload {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            vertical-align: top;
            width: 100px;
            font-weight: 400;
            border-radius: 5px;
            line-height: 43px;
            margin-right: 7px;
            background-color: #d9d9d9;
        }
        input[type="file"] {
            display: none;
        }
        p {
            &.inp {
                font-size: 14px;
                color: #999;
                width: calc(100% - 111px);
                font-weight: 300;
            }
        }
        &.file-active {
            .file-upload {
                
            }
            .file {
                display: inline-block;
                width: 180px;
                vertical-align: middle;
            }
            p {
                &.inp {
                    width: calc(100% - 294px);
                }
            }
        }
    }
    &.addr {
        position: relative;
        padding-left: 130px;
        .btn-addr {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            text-align: center;
            cursor: pointer;
            width: 120px;
            font-weight: 400;
            border-radius: 5px;
            line-height: 43px;
            background-color: #d9d9d9;
        }
        input {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 525px);
            &:first-of-type {
                width: 500px;
                margin-right: 10px;
            }
        }
    }
}

article {
    &.space {
        margin-top: 65px;
    }
}

.sub-title {
    font-size: 27px;
    font-weight: 700;
    &.blue {
        color: #223995;
    }
}

.article-header {
    padding: 65px 0 30px;
    &.type-2 {
        padding-bottom: 30px;
    }
}

.join-success {
    text-align: center;
    .article-content {
        .tbl-area {
            width: 600px;
            margin: 0 auto;
        }
    }
}

.btn-area {
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
    .pw-write, .btn-login {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        width: 120px;
        font-weight: 400;
        border-radius: 5px;
        line-height: 43px;
        background-color: #d9d9d9;
        &.btn-login {
            width: 100%;
            max-width: 120px;
        }
    }
}

textarea.inp {
    height: 100px;
    padding: 14px;
}

/* small-pop */
.small-pop {
    padding: 0 15px;
    padding-bottom: 60px;
    .popup-header {
        width: 100%;
    }
    .article-header {
        position: relative;
        /*text-align: center;*/
        padding: 40px 0 30px;
        &.left {
            text-align: left;
        }
        h5 {
            font-size: 20px;
        }
        p {
            &.center {
                text-align: center;
            }
            &.blue {
                color: #042dbe;
            }
        }
        .btn-close {
            background: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        .dott-list {
            text-align: left;
            > li {
                position: relative;
                padding-left: 15px;
                &:before {
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    display: block;
                    border-radius: 50%;
                    content: '';
                    background-color: #222;
                }
                &.note {
                    color: #e94316;
                    &:before {
                        background-color: #e94316;
                    }
                }
            }
        }
    }
    .article-content {
        > figure {
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        .attention {
            color: #999;
            text-align: center;
            margin-top: 60px;
        }
        .btn-area {
            margin-top: 60px;
        }
    }
}

.tbl-base {
    td {
        .subject {
            display: block;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            padding: 0 5px;
            text-overflow: ellipsis;
        }
        .file-list {
            > a {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.popup-header {
    &.type-2 {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.cont-area__grid {
    &.type-2 {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        
    }
}


.pb-60 {
    padding-bottom: 60px !important;
}


.product-num {
    /*display: table;
    width: 100%;
    table-layout: fixed;*/
    > div {
        display: block;
        /*display: table-cell;
        vertical-align: top;*/
        &.item {
            /*padding-right: 5px;*/
            img {
                display: block;
                width: 100%;
            }
        }
        &.summary {
            /*padding-left: 5px;*/
            margin-top: 20px;
        }
    }
}



/* tbl-upper */
.tbl-upper {
    .tbl-upper__util {
        .top-board {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            color: #223995;
            padding: 0 20px;
            line-height: 43px;
            border-radius: 5px;
            border: 1px solid #9fa8c0;
            background-color: #F8F8F8;
        }
    }
}

/* file-upload */
.file-upload {
    overflow: hidden;
    .btn-file {
        float: left;
        cursor: pointer;
        width: 140px;
        color: #fff;
        line-height: 54px;
        text-align: center;
        border-radius: 5px;
        background-color: #223995;
    }
    input[type="file"] {
        float: left;
        height: 54px;
        max-width: 300px;
        width: calc(100% - 150px);
        margin-left: 10px;
        border-radius: 5px;
        border: 1px solid #9fa8c0;
    }
    .file-list {
        float: left;
        width: calc(100% - 150px);
        margin-left: 10px;
        p {
            display: block;
            font-size: 16px;
            color: #223995;
            line-height: 54px;
        }
    }
}

/* format-area */
.format-area {
    .btn-format {
        width: 140px;
        color: #fff;
        line-height: 54px;
        text-align: center;
        border-radius: 5px;
        background-color: #7f7f7f;
    }
}

.dott-list {
    > li {
        position: relative;
        padding-left: 10px;
        font-size: 16px;
        line-height: 26px;
        span {
            color: #e94316;
        }
        &:before {
            position: absolute;
            top: 11px;
            left: 0;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            display: block;
            content: '';
            background-color: #000;
        }
    }
}

/* board-frame */
.board-frame {
    display: block;
    width: 100%;
    line-height: 52px;
    text-align: center;
    color: #fff;
    overflow: hidden;
    background-color: #558ed5;
    &.type-2 {
        line-height: normal;
        padding: 30px 0;
    }
    span {
        color: #ffff00;
    }
    .under-txt {
        &:before {
            display: inline-block;
            content: '|';
            color: #fff;
            margin: 0 10px;
        }
    }
    > div {
        float: left;
        width: 50%;
        line-height: 52px;
        text-align: center;
        color: #fff;
        &:nth-of-type(1) {
            background-color: #558ed5;
        }
        &:nth-of-type(2) {
            background-color: #ff7c80;
        }
    }
}

/* summary-top */
.summary-top {
    &.col4 {
        display: table;
        width: 100%;
        table-layout: fixed;
        > div {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            &.summary-list {
                text-align: left;
                width: 67%;
                padding: 30px;
                background-color: #4e83e6;
                ol {
                    > li {
                        color: #fff;
                        line-height: 26px;
                    }
                }
            }
            &.bank-info {
                text-align: center;
                > dl {
                    display: table;
                    width: 100%;
                    height: 100%;
                    > dt, dd {
                        height: 164px;
                        display: table-cell;
                        vertical-align: middle;
                    }
                    > dt {
                        width: 100px;
                        color: #fff;
                        background-color: #e94316;
                    }
                    > dd {
                        overflow: hidden;
                        color: #fff;
                        background-color: #333;
                        > ul {
                            font-size: 0;
                            li {
                                position: relative;
                                display: inline-block;
                                width: 50%;
                                font-size: 16px;
                                &:after {
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    width: 1px;
                                    height: 300px;
                                    transform: translateY(-50%);
                                    display: block;
                                    background-color: rgba(#fff, .6);
                                    content: '';
                                }
                                &:last-child {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* files */
.files {
    > li {
        > .file {
            position: relative;
            display: inline-block;
            > figure {
                display: inline-block;
                img {
                    width: 20px;
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
                figcaption {
                    color: #333;
                    font-size: 14px;
                    font-weight: 300;
                    margin-top: 5px;
                }
            }
            .btn-del {
                position: absolute;
                top: -10px;
                right: 10px;
                display: block;
            }
        }
        & ~ li {
            margin-top: 10px;
        }
    }
}


.article-content {
    &.type-2 {
        overflow: hidden;
        .box {
            float: left;
            width: 970px;
            &.left {
                width: calc(100% - 1000px);
                margin-right: 30px;
                .tbl-area__tit {
                    display: block;
                    text-align: center;
                    line-height: 43px;
                    color: #000;
                }
                .table-wrap {
                    table {
                        tbody {
                            tr {
                                td {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.tbl-area {
            
        }
    }
}

.introduce {
    width: 1560px;
    margin: 0 auto;
    article {
        text-align: center;
        .article-title {
            small {
                display: block;
                font-size: 20px;
                color: #222;
                font-weight: 400;
            }
            h3 {
                font-size: 32px;
                color: #222;
                font-weight: bold;
            }
            &.white {
                h3 {
                    color: #fff;
                }
                small {
                    color: #fff;
                }
            }
        }
        &.article-1 {
            padding: 90px 0 100px;
            .article-title {
                padding-bottom: 50px;
            }
            .article-content {
                figure {
                    img {
                        display: block;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                    figcaption {
                        font-size: 20px;
                        line-height: 36px;
                        color: #222;
                        font-weight: 400;
                        padding-top: 50px;
                    }
                }
            }
        }
        &.article-2 {
            padding: 85px 0;
            background-image: url(/resource/img/bg_product.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            .article-title {
                padding-bottom: 50px;
            }
            .article-content {
                > ul {
                    text-align: center;
                    font-size: 0;
                    > li {
                        display: inline-block;
                        width: 160px;
                        margin-right: 23px;
                        vertical-align: top;
                        > a {
                            display: block;
                            width: 100%;
                            background-color: #fff;
                            box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.57);
                            > figure {
                                > img {
                                    width: 100%;
                                    display: block;
                                }
                                figcaption {
                                    padding-bottom: 25px;
                                    b {
                                        display: block;
                                        font-size: 20px;
                                        color: #000;
                                        font-weight: 500;
                                    }
                                    p {
                                        margin: 5px 0 15px;
                                        font-size: 13px;
                                        color: #989898;
                                        font-weight: 500;
                                        font-family: 'Montserrat', sans-serif;
                                    }
                                    img {
                                        display: block;
                                        margin: 0 auto;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        
                    }
                }
            }
        }
        &.article-3 {
            padding: 110px 0 90px;
            .article-title {
                padding-bottom: 70px;
            }
            .article-content {
                > ol {
                    font-size: 0;
                    > li {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        padding: 0 45px;
                        &:after {
                            position: absolute;
                            top: 50%;
                            right: -24px;
                            display: block;
                            transform: translateY(-50%);
                            content: url(/resource/img/ico_step_arrow.png);
                        }
                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                        > figure {
                            img {
                                display: block;
                                margin: 0 auto;
                                max-width: 100%;
                            }
                            figcaption {
                                padding-top: 30px;
                                > dl {
                                    > dt {
                                        font-size: 20px;
                                        font-weight: 500;
                                        color: #000;
                                    }
                                    > dd {
                                        display: block;
                                        font-size: 16px;
                                        line-height: 20px;
                                        color: #000;
                                        font-weight: 300;
                                        line-height: 20px;
                                        margin-top: 10px;
                                        span {
                                            display: block;
                                            color: #8d8d8d;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.inquiry {
    text-align: center;
    padding: 90px 0;
    background-color: #f0f4fc;
    .container {
        width: 1560px;
        margin: 0 auto;
        .article-title {
            padding-bottom: 40px;
            h3 {
                font-size: 32px;
                color: #222;
                font-weight: bold;
            }
        }
        .article-content {
            .company-info {
                margin-top: 45px;
                p {
                    font-size: 20px;
                    line-height: 40px;
                    font-weight: 300;
                    color: #222;
                    vertical-align: top;
                    b {
                        display: inline-block;
                        vertical-align: top;
                        font-weight: 500;
                        line-height: 40px;
                        padding-right: 5px;
                        &.blue {
                            color: #15459d;
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
}



.default-board {
    border: 1px solid #d8dee5;
    .board-header {
        background-color: #f8f8f8;
        padding: 10px 20px;
        h5 {
            font-size: 20px;
        }
    }
    .board-content {
        padding: 20px;
        min-height: 500px;
        border-top: 1px solid #d8dee5;
    }
    .file-list {
        padding: 10px 20px;
        border-top: 1px solid #d8dee5;
        ul {
            li {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                > a {
                    display: block;
                    img {
                        width: 15px;
                    }
                }
            }
        }
    }
}























































