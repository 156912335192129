@charset 'UTF-8';

/* 
 *	eunjye's reset : https://velog.io/@eunjye
 *	(based on Eric Meyer's Reset css v2.0 : http://meyerweb.com/eric/tools/css/reset/)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video, button, input, main { margin:0; padding:0; border:0; font:inherit; vertical-align:baseline; color:inherit; box-sizing:border-box; line-height:1.4em; text-decoration:inherit; }
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display:block; }
html, body { width:100%; height:100%; }
*:after, *:before { box-sizing:border-box; }
body { line-height:1.4em; }
ol, ul { list-style:none; }
blockquote, q { quotes:none; }
blockquote:before, blockquote:after, q:before, q:after { content:''; content:none; }
table { border-collapse:collapse; border-spacing:0; table-layout:fixed; }
img, button, a { vertical-align:middle; }
button { background:none; border:none; cursor:pointer; }
input { border: none; appearance: none; }
caption { opacity:0; position:absolute; display:block; width:1px; height:1px; overflow:hidden; color:transparent; }
select{-webkit-appearance: none; -moz-appearance: none;appearance: none; }
select::-ms-expand {display: none;}